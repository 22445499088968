import type { StandalonePrice, StandalonePricePagedQueryResponse } from '@commercetools/platform-sdk'
import { QueryFunctionContext } from 'react-query'

import client from 'commercetools/client'

// #region API utils

function prepareKeys(id: string | ReadonlyArray<string | undefined> | undefined): string {
  return (Array.isArray(id) ? id : [id])
    .filter(Boolean)
    .map((value) => `"${value}"`)
    .join(',')
}

function prepareOr(...args: ReadonlyArray<string>): string {
  const filtered = args.filter(Boolean)
  if (filtered.length === 0) {
    return ''
  }
  return `(${filtered.join(' or ')})`
}

function getParams(sku: string | ReadonlyArray<string | undefined> | undefined, priceChannelId: string | undefined) {
  const now = new Date()
  const utcDateTimeNow = now.toISOString()

  return new URLSearchParams({
    where: [
      `sku in (${prepareKeys(sku)})`,
      prepareOr('validFrom is not defined', `validFrom < "${utcDateTimeNow}"`),
      prepareOr('validUntil is not defined', `validUntil > "${utcDateTimeNow}"`),
      prepareOr('channel is not defined', priceChannelId ? `channel(id in (${prepareKeys(priceChannelId)}))` : ''),
    ].join(' and '),
  }).toString()
}

function getMatchingPrice(priceChannelId: string | undefined, prices: ReadonlyArray<StandalonePrice>, sku: string) {
  const pricesForSku = prices.filter((price) => price.sku === sku)
  const priceForTheCustomer = priceChannelId && pricesForSku.find((price) => price.channel?.id === priceChannelId)
  if (priceForTheCustomer) {
    return priceForTheCustomer
  }
  return pricesForSku[0]
}
// #endregion

// #region API requests
type QueryKeySingle = [queryFnName: 'getStandalonePrice', sku: string | undefined, priceChannelId?: string]

/**
 * Use this function to get standalone prices for a single product.
 */
export function getStandalonePrice({
  signal,
  queryKey: [, sku, priceChannelId],
}: QueryFunctionContext<QueryKeySingle>) {
  if (!sku) {
    throw new Error('Product SKU is missing!')
  }

  return client
    .get<StandalonePricePagedQueryResponse>(`/standalone-prices?${getParams(sku, priceChannelId)}`, void 0, { signal })
    .then((response) => getMatchingPrice(priceChannelId, response?.results || [], sku))
}

type QueryKeyMultiple = [
  queryFnName: 'getStandalonePrice',
  skus: ReadonlyArray<string | undefined> | undefined,
  priceChannelId?: string,
]

/**
 * Use this function to get standalone prices for multiple products.
 */
export function getStandalonePrices({
  signal,
  queryKey: [, skus, priceChannelId],
}: QueryFunctionContext<QueryKeyMultiple>): Promise<Record</* sku */ string, StandalonePrice>> {
  if (!skus || !skus?.length) {
    throw new Error('Product SKUs are missing!')
  }

  return client
    .get<StandalonePricePagedQueryResponse>(`/standalone-prices?${getParams(skus, priceChannelId)}`, void 0, { signal })
    .then((response) =>
      skus
        .filter((sku): sku is string => !!sku)
        .reduce<Record<string, StandalonePrice>>(
          (acc, sku) => ({ ...acc, [sku]: getMatchingPrice(priceChannelId, response?.results || [], sku) }),
          {},
        ),
    )
}
// #endregion
