import { Button, Center, Container, Flex, Heading, Spinner } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, ReactNode } from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { TowTruckIcon } from 'components/elements/Icons/TowTruckIcon'
import { CHANGE_COMPANY_BUTTON_ID } from 'components/modules/Account/AccountMenu'
import hasBusinessUnitAccessToStore from 'utils/hasBusinessUnitAccessToStore'

interface CompanyAccessProps {
  children?: ReactNode
}

const TAG = 'CompanyAccess'

const changeCompany = () => document.getElementById(CHANGE_COMPANY_BUTTON_ID)?.click()

/**
 * Guard to handle situation when the customer has access to the store (one of his companies has access)
 * but the selected one doesn't have access to the store. User is already logged in here.
 * Handling situation when the customer has access to the store in general is done in @see RouteGuard
 */
const CompanyAccess: FunctionComponent<CompanyAccessProps> = ({ children }) => {
  const { t } = useTranslation('common')

  const { current, status } = useBusinessUnit()
  const hasAccess = hasBusinessUnitAccessToStore(current)

  if (status === 'loading' || status === 'idle') {
    return (
      <Center my={10}>
        <Spinner size="xl" />
      </Center>
    )
  }

  if (!hasAccess) {
    return (
      <Container
        alignSelf="center"
        justifySelf="center"
        m={{ base: 0, md: 10 }}
        width="desktop-content"
        height="full"
        p={0}
        background="primary.white"
      >
        <Flex p={13} direction="column" alignItems="center" justifyContent="center" textAlign="center">
          <Flex
            boxSize="11.5rem"
            fontSize="sm"
            borderRadius="50%"
            bgColor="secondary.beige.100"
            alignItems="center"
            justifyContent="center"
          >
            <TowTruckIcon boxSize="5.75rem" color="primary.red" />
          </Flex>
          <Heading as="h1" mb="12" mt="8" fontSize={{ base: 'lg', sm: 'lg' }}>
            {t('company-doesnt-have-access')}
          </Heading>
          <Button onClick={changeCompany}>{t('change-company')}</Button>
        </Flex>
      </Container>
    )
  }

  return <>{children}</>
}

CompanyAccess.displayName = TAG

export default CompanyAccess
