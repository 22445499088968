import getSiteURL from 'utils/getSiteURL'

const NORMET_SITE_URL = process.env.NORMET_SITE_URL

function getNormetLandingURL(): string {
  // Local
  const siteURL = getSiteURL()
  if (siteURL && siteURL.includes('localhost')) {
    const url = new URL(siteURL)
    url.hash = '#localhost-landing'
    return url.toString()
  }

  if (!NORMET_SITE_URL) {
    console.error('Could not find Landing URL. Please set environment variable `NORMET_SITE_URL`.')
    return '#'
  }

  // DEV, QA, PROD
  return NORMET_SITE_URL
}

export default getNormetLandingURL
