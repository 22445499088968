import {
  Box,
  BoxProps,
  Button,
  IconButton,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {
  Cart,
  CartChangeLineItemQuantityAction,
  ErrorObject,
  LineItem,
  ProductProjection,
} from '@commercetools/platform-sdk'
import debounce from 'lodash/debounce'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'

import { removeLineItem, changeLineItemQuantity, addLineItem } from 'commercetools/api/actions/cart'
import { useBranches } from 'commercetools/hooks/use-branches'
import { useCart } from 'commercetools/hooks/use-cart'
import { usePriceChannels } from 'commercetools/hooks/use-price-channel'
import { getProductVariant } from 'commercetools/utils/product/getProductVariant'
import { AddToCartIcon } from 'components/elements/Icons/AddToCartIcon'
import { MinusIcon } from 'components/elements/Icons/MinusIcon'
import { PlusIcon } from 'components/elements/Icons/PlusIcon'
import { TrashCanIcon } from 'components/elements/Icons/TrashCanIcon'
import { trackAddProductToCart } from 'gtm/trackAddProductToCart'
import { trackRemoveProductFromCart } from 'gtm/trackRemoveProductFromCart'
import getRequestPriceUrl from 'utils/getRequestPriceUrl'

const ConfirmationModal = dynamic(
  () => import('components/elements/ConfirmationModal').then((modal) => modal.ConfirmationModal),
  { ssr: false },
)

type QuantityInputType = BoxProps & {
  lineItem?: LineItem
  product?: ProductProjection
  setQuantityDialogIsOpen?: Dispatch<SetStateAction<boolean>>
  showTrashButton?: boolean
  showExceedsQuantityInStockMessage?: boolean
  isMiniCart?: boolean
  gtagPageType?: string
}

export const QuantityInput = ({
  lineItem,
  product,
  setQuantityDialogIsOpen,
  showTrashButton = false,
  showExceedsQuantityInStockMessage = false,
  isMiniCart = false,
  gtagPageType,
  ...props
}: QuantityInputType) => {
  const productVariant = getProductVariant(product)
  const { t, lang } = useTranslation('common')
  //Select all of the input fields values on click
  const selectText = (input: HTMLInputElement) => input.select()
  const preventNonDigits = (event: React.KeyboardEvent) => {
    if (isNaN(Number(event.key))) {
      event.preventDefault()
    }
  }

  const [isSourceComponentForUpdate, setIsSourceComponentForUpdate] = useState(false)
  const [quantity, setQuantity] = useState(lineItem?.quantity)

  useEffect(() => {
    if (!isSourceComponentForUpdate) {
      if (lineItem?.quantity !== quantity) {
        setQuantity(lineItem?.quantity)
      }
    }
  }, [isSourceComponentForUpdate, lineItem, quantity])

  const [, updateCart] = useCart()

  const confirmDeleteLineItemModal = useDisclosure()
  useEffect(() => {
    if (setQuantityDialogIsOpen) {
      setQuantityDialogIsOpen(confirmDeleteLineItemModal.isOpen)
    }
  }, [confirmDeleteLineItemModal.isOpen, setQuantityDialogIsOpen])

  const removeMutation = useMutation(updateCart)

  const OnDeleteLineItem = async () => {
    if (!lineItem) {
      return
    }

    await removeMutation.mutateAsync(removeLineItem(lineItem.id)).then(() => trackRemoveProductFromCart(lineItem, lang))
    setQuantity(0)
    setIsSourceComponentForUpdate(false)
    confirmDeleteLineItemModal.onClose()
  }

  const changeQuantityMutation = useMutation<Cart, ErrorObject, CartChangeLineItemQuantityAction>(updateCart, {
    onError: console.error,
  })

  const updateQuantityDebounced = useMemo(
    () => debounce(changeQuantityMutation.mutateAsync, 300, { trailing: true }),
    [changeQuantityMutation.mutateAsync],
  )
  const trackQuantity = useCallback(
    (currentQuantity: number) => {
      if (lineItem?.quantity && currentQuantity && lineItem?.quantity !== currentQuantity) {
        currentQuantity > lineItem?.quantity
          ? trackAddProductToCart({
              sku: lineItem?.variant?.sku,
              price: lineItem?.price,
              name: lineItem?.name,
              lang,
              gtagPageType,
              quantity: currentQuantity - lineItem?.quantity,
            })
          : trackRemoveProductFromCart(lineItem, lang, lineItem?.quantity - currentQuantity)
      }
    },
    [lineItem, gtagPageType, lang],
  )
  const updateTrackingDebounced = useMemo(
    () => debounce((quantity) => trackQuantity(quantity), 500, { trailing: true }),
    [trackQuantity],
  )

  const { id: priceChannel } = usePriceChannels()
  const { id: supplyChannel } = useBranches()

  const onQuantityChange = async (quantity: number) => {
    if (quantity === 0) {
      confirmDeleteLineItemModal.onOpen()
    } else {
      if (lineItem) {
        setQuantity(quantity)
        setIsSourceComponentForUpdate(true)
        updateQuantityDebounced(changeLineItemQuantity(lineItem.id, quantity))
        updateTrackingDebounced(quantity)
      } else {
        if (!productVariant?.sku) {
          return
        }

        updateCart([
          addLineItem({
            sku: productVariant.sku,
            distributionChannel: {
              id: priceChannel,
              typeId: 'channel',
            },
          }),
        ]).then(() =>
          trackAddProductToCart({
            sku: productVariant?.sku,
            price: productVariant?.price,
            name: product?.name,
            lang,
            gtagPageType,
            quantity,
          }),
        )
      }
    }
  }

  const priceIsAvailable =
    (product?.standalonePrice?.value?.centAmount || 0) > 0 ||
    (lineItem?.price && lineItem?.price?.value?.centAmount > 0)

  const requestPriceUrl = getRequestPriceUrl(lineItem ? lineItem.variant?.sku : productVariant?.sku)

  const getExceedsQuantityInStockMessage = () => {
    const isOnStock = supplyChannel && lineItem?.variant?.availability?.channels?.[supplyChannel]?.isOnStock
    if (!isOnStock) {
      return
    }

    const availableQuantity = lineItem?.variant?.availability?.channels[supplyChannel].availableQuantity || 0
    const quantityValue = quantity || 0
    if (availableQuantity < quantityValue) {
      const difference = availableQuantity <= 0 ? quantityValue : quantityValue - availableQuantity
      return `${difference} ${difference > 1 ? t('items-on-backorder') : t('item-on-backorder')}`
    }
  }

  return (
    <>
      <Box {...props}>
        <ConfirmationModal
          title={t('please-confirm-title')}
          onConfirm={OnDeleteLineItem}
          isOpen={confirmDeleteLineItemModal.isOpen}
          onClose={confirmDeleteLineItemModal.onClose}
        >
          <Text fontSize="sm">{t('confirm-delete-item-text')}</Text>
        </ConfirmationModal>

        {!priceIsAvailable ? (
          <Link href={requestPriceUrl} as={NextLink}>
            <Text whiteSpace="nowrap">{t('request-price')}</Text>
          </Link>
        ) : !!quantity && quantity > 0 ? (
          <Stack alignItems="end" minW="120px">
            <NumberInput
              display="flex"
              border="2px"
              borderColor="primary.grey.100"
              borderRadius="2px"
              errorBorderColor="transparent"
              focusInputOnChange={false}
              p="0.5"
              maxW="110px"
              min={0}
              max={999}
              value={quantity}
              onChange={(_, valueAsNumber) => onQuantityChange(valueAsNumber)}
            >
              <NumberDecrementStepper border="none">
                <Button size="sm" maxW={8} variant="outline">
                  <MinusIcon boxSize={3} />
                </Button>
              </NumberDecrementStepper>

              <NumberInputField
                inputMode="numeric"
                min={1}
                h={8}
                minW={9}
                p={0}
                border="none"
                textAlign="center"
                _focus={{ border: 'none' }}
                onClick={(event) => selectText(event.target as HTMLInputElement)}
                onKeyPress={preventNonDigits}
              />

              <NumberIncrementStepper border="none">
                <Button size="sm" maxW={8}>
                  <PlusIcon boxSize={3} />
                </Button>
              </NumberIncrementStepper>
            </NumberInput>
            {showExceedsQuantityInStockMessage && !isMiniCart && (
              <Text fontSize="xs" color="primary.red">
                {getExceedsQuantityInStockMessage()}
              </Text>
            )}
          </Stack>
        ) : (
          <IconButton
            borderRadius="2px"
            variant="ghost"
            size="xs"
            aria-label="add-to-cart"
            icon={<AddToCartIcon color="primary.red" boxSize={4} />}
            onClick={() => onQuantityChange(1)}
            _hover={{ backgroundColor: 'primary.grey.100' }}
          />
        )}
      </Box>
      {showTrashButton && (
        <Button
          alignSelf={isMiniCart ? 'end' : 'start'}
          mt={isMiniCart ? '0px' : '12px !important'}
          size="min"
          rightIcon={<TrashCanIcon boxSize={4} />}
          variant="ghost"
          onClick={confirmDeleteLineItemModal.onOpen}
        />
      )}
    </>
  )
}
