/* eslint-disable react/jsx-no-target-blank */
import { Link } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent } from 'react'

import { buttonStyles, beforeElement } from 'components/modules/Header/styles'
import { SiteKey } from 'hooks/useNormetSitesLinks'

interface OtherSiteLinkProps {
  href: string
  target: string
  siteKey: SiteKey
}

const TAG = 'SiteLink'

const styles = {
  button: buttonStyles,
  underline: { _before: beforeElement },
} as const

const SiteLink: FunctionComponent<OtherSiteLinkProps> = ({ href, target, siteKey }) => {
  const { t } = useTranslation()

  const label = t(`sites.${siteKey}`)

  return (
    <Link
      {...styles.button}
      {...(siteKey === 'store' ? { ...styles.underline } : {})}
      href={href}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      aria-label={label}
    >
      {label}
    </Link>
  )
}

SiteLink.displayName = TAG

export default SiteLink
