
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { getComponentLayout } from 'config/layout';
import { gtagPageView } from 'gtm/gtag';
import { useRouteChangeEffect } from 'hooks/useRouteChangeEffect';
import theme from 'theme';
import Auth from 'utils/Providers/Auth';
import QueryClient from 'utils/Providers/QueryClient';
function App({ Component, pageProps, router }: AppProps) {
    useRouteChangeEffect({
        onComplete: gtagPageView,
    });
    const getLayout = getComponentLayout(Component, pageProps, router.route);
    return (<QueryClient>
      <Auth>
        <ChakraProvider theme={theme}>{getLayout(<Component {...pageProps}/>)}</ChakraProvider>
      </Auth>
    </QueryClient>);
}
/**
 * @link https://nextjs.org/docs/advanced-features/measuring-performance#sending-results-to-analytics
 */
export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
    if (typeof window.gtag === 'function') {
        window.gtag('event', name, {
            event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
            value: Math.round(name === 'CLS' ? value * 1000 : value),
            event_label: id,
            non_interaction: true,
        });
    }
}
const __Next_Translate__Page__192005197ea__ = App;

    export default __appWithI18n(__Next_Translate__Page__192005197ea__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  