import { extendTheme, theme as base } from '@chakra-ui/react'

// Component style overrides
import {
  AccountMenu,
  Alert,
  Button,
  Checkbox,
  Container,
  Divider,
  Drawer,
  Form,
  FormError,
  Heading,
  Input,
  Link,
  Menu,
  Modal,
  Popover,
  Radio,
  ScrollableFlex,
  Select,
  Switch,
  Table,
  Tabs,
} from './components'
// Foundational style overrides
import { colors, fontSizes, sizes, space } from './foundations'
// Global style overrides
import { global } from './global'

const overrides = {
  config: {
    cssVarPrefix: 'nm',
  },
  styles: {
    global,
  },
  colors,
  space,
  sizes,
  fonts: {
    heading: `Proxima Nova, ${base.fonts?.heading}`,
    body: `Proxima Nova, ${base.fonts?.body}`,
  },
  fontSizes,
  components: {
    Alert,
    Drawer,
    Container,
    Modal,
    Tabs,
    Button,
    Heading,
    Select,
    Link,
    Form,
    FormError,
    Input,
    Checkbox,
    Table,
    Radio,
    Popover,
    Menu,
    Divider,
    AccountMenu,
    ScrollableFlex,
    Switch,
  },
}

export default extendTheme(overrides)
