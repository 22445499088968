import { useMemo } from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { RoutePaths } from 'config/routes'
import getNormetLandingURL from 'utils/getNormetLandingURL'
import getNormetPortalURL from 'utils/getNormetPortalURL'
import getSiteURL from 'utils/getSiteURL'
import { hasCustomerAccessToAnalyticsPortal } from 'utils/hasAccessToAnalyticsPortal'
import { hasCustomerAccessToPortal } from 'utils/hasAccessToPortal'

const ANALYTICS_PORTAL_URL = process.env.NORMET_ANALYTICS_PORTAL_URL

export type SiteKey = 'portal' | 'store' | 'landing' | 'analytics'

interface Options {
  /**
   * @default false
   */
  addUTMParams?: boolean
}

export const UTM_PARAMS = {
  utm_source: 'store',
  utm_medium: 'referral',
  utm_content: getSiteURL(),
}

function useNormetSitesLinks({ addUTMParams = false }: Options) {
  const { all } = useBusinessUnit()

  const hasAccessToPortal = hasCustomerAccessToPortal(all)
  const hasAccessToAnalyticsPortal = hasCustomerAccessToAnalyticsPortal(all)

  return useMemo(() => {
    const landingUrl = new URL(getNormetLandingURL())

    if (addUTMParams) {
      landingUrl.search = new URLSearchParams(UTM_PARAMS).toString()
    }

    const portalUrl = new URL(getNormetPortalURL(hasAccessToPortal))
    if (addUTMParams) {
      portalUrl.search = new URLSearchParams(UTM_PARAMS).toString()
    }

    const analyticsUrl = hasAccessToAnalyticsPortal ? new URL(ANALYTICS_PORTAL_URL) : ''

    return {
      portal: { href: portalUrl.toString(), siteKey: 'portal', target: '_self' },
      store: { href: RoutePaths.home, siteKey: 'store', target: '_self' },
      landing: { href: landingUrl.toString(), siteKey: 'landing', target: '_blank' },
      analytics: { href: analyticsUrl.toString(), siteKey: 'analytics', target: '_blank' },
    } as const
  }, [addUTMParams, hasAccessToPortal, hasAccessToAnalyticsPortal])
}

export default useNormetSitesLinks
