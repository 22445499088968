const flattenParams = (key: string, values: Array<string | number>): string =>
  values
    .filter(Boolean)
    .map((value) => `${key}=${encodeURIComponent(value)}`)
    .join('&')

type Config = {
  omitEncoding: boolean
}

type Params = {
  [key: string | number]: string | number | boolean | Array<string | number>
}

export const buildURLSearchParams = <P = Params>(params: P, config?: Config): string => {
  const { omitEncoding } = config || {}
  const encode = omitEncoding ? encodeURI : encodeURIComponent

  return Object.entries(params as Params)
    .filter(([, value]) => Boolean(value))
    .map(([key, value]) => (Array.isArray(value) ? flattenParams(key, value) : `${key}=${encode(value.toString())}`))
    .join('&')
}
