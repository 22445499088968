import { useMsal } from '@azure/msal-react'
import { Button, Stack, useDisclosure } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { default as NextLink } from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import Error from 'components/elements/Error'
import { NO_ACCESS_TO_STORE_HREF } from 'config/auth'
import { RoutePaths } from 'config/routes'

interface NoAccessErrorProps {
  type: 'customer' | 'businessUnit'
}

const TAG = 'NoAccessError'

const ChangeCompanyModal = dynamic(
  () => import('components/elements/ChangeCompanyModal').then((modal) => modal.ChangeCompanyModal),
  { ssr: false },
)

const NoAccessError: FunctionComponent<NoAccessErrorProps> = ({ type }) => {
  const { t } = useTranslation()
  const modal = useDisclosure()

  const { instance } = useMsal()
  const logOut = () => {
    const account = instance.getActiveAccount()
    instance.logoutRedirect({ account, postLogoutRedirectUri: RoutePaths.login })
  }

  if (type === 'customer') {
    return (
      <Error
        title={t('auth.no-access.title')}
        description={t('auth.no-access.description')}
        button={
          <Stack>
            <Button variant="primary" as={NextLink} href={NO_ACCESS_TO_STORE_HREF}>
              {t('auth.no-access.button')}
            </Button>
            <Button variant="primary" onClick={logOut}>
              {t('sign-out')}
            </Button>
          </Stack>
        }
        p="0"
        m="0"
      />
    )
  }
  return (
    <>
      <Error
        title={t('auth.no-business-unit-access.title')}
        description={t('auth.no-business-unit-access.description')}
        button={
          <Stack>
            <Button variant="primary" onClick={modal.onOpen} />
            <Button variant="primary" onClick={logOut}>
              {t('sign-out')}
            </Button>
          </Stack>
        }
      />
      <ChangeCompanyModal {...modal} />
    </>
  )
}

NoAccessError.displayName = TAG

export default NoAccessError
