export const global = {
  html: {
    height: '100%',
    // Modal hides scrollbar, so we need to update gutter accordingly
    '&:not(:has(.chakra-portal [aria-modal="true"]))': {
      scrollbarGutter: 'stable',
    },
  },
  body: {
    color: 'primary.black',
    printColorAdjust: 'exact',
    scrollbarWidth: 'auto',
  },
  'body:not(:has(#LoginLayout))': {
    bg: 'primary.grey.100',
  },
  a: {
    cursor: 'pointer',
  },
  svg: {
    fill: 'currentColor',
    overflow: 'visible',
  },
  '*::-webkit-scrollbar': {
    width: '0.375rem',
    height: '0.375rem',
  },
  'body::-webkit-scrollbar': {
    width: '0.75rem',
    height: '0.75rem',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccc',
    border: 'none',
    borderRadius: '1rem',
    transition: 'background-color 0.2s ease',
  },
  '*::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#999',
  },
  // https://syntackle.com/blog/changes-to-scrollbar-styling-in-chrome-121/
  '@supports not selector(::-webkit-scrollbar)': {
    '*': {
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--scrollbar-thumb-color)  var(--scrollbar-track-color)',
    },
  },
  '::selection': {
    background: '#FEB2B2',
    color: '#000',
  },
  '@font-face': [
    {
      fontFamily: `'Proxima Nova'`,
      fontStyle: 'normal',
      fontWeight: 400,
      fontDisplay: 'swap',
      src: [
        `local('Proxima Nova Regular')`,
        `local('ProximaNova-Regular')`,
        `local('Proxima Nova')`,
        `url('/fonts/ProximaNovaRegular.woff2') format('woff2')`,
        `url('/fonts/ProximaNovaRegular.woff') format('woff')`,
        `url('/fonts/ProximaNovaRegular.otf') format('opentype')`,
      ].join(),
    },
    {
      fontFamily: `'Proxima Nova'`,
      fontStyle: 'normal',
      fontWeight: 700,
      fontDisplay: 'swap',
      src: [
        `local('Proxima Nova Bold')`,
        `local('ProximaNova-Bold')`,
        `local('Proxima Nova')`,
        `url('/fonts/ProximaNovaBold.woff2') format('woff2')`,
        `url('/fonts/ProximaNovaBold.woff') format('woff')`,
        `url('/fonts/ProximaNovaBold.otf') format('opentype')`,
      ].join(),
    },
    // Generated with `pixel-point/fontpie`
    {
      fontFamily: 'Adjusted Arial Fallback',
      src: 'local(Arial)',
      fontStyle: 'normal',
      fontWeight: 400,
      sizeAdjust: '99%',
      ascentOverride: '99%',
      descentOverride: '26%',
      lineGapOverride: '0%',
    },
    {
      fontFamily: 'Adjusted Arial Fallback',
      src: "local('Arial Bold')",
      fontStyle: 'normal',
      fontWeight: 700,
      ascentOverride: '97.08%',
      descentOverride: '31.44%',
      lineGapOverride: '0.00%',
      sizeAdjust: '94.77%',
    },
  ],
  /* Cookiebot */
  'a#CybotCookiebotDialogPoweredbyCybot': {
    display: 'none',
  },
  'div#CybotCookiebotDialogPoweredByText': {
    display: 'none',
  },
}
