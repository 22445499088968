import type {
  Customer,
  CustomerUpdateAction,
  LocalizedString,
  ProductProjection,
  ShoppingListUpdateAction,
  TypedMoney,
  UpdateAction,
} from '@commercetools/platform-sdk'

export type UseCustomerMutation = {
  updateCurrentCustomer: (actions: CustomerUpdateAction | CustomerUpdateAction[], message?: string) => Promise<Customer>
  isUpdating: boolean
}

export type UpdateActionValue = {
  id?: string
  version: number
  actions: UpdateAction | UpdateAction[]
}

export type UpdateShoppingListValue = {
  version: number
  actions: ShoppingListUpdateAction | ShoppingListUpdateAction[]
}

export type TokenResponse = {
  access_token?: string
  refresh_token?: string
  expires_in?: string
}

export type VariantAttribute =
  | 'productMedia'
  | 'productVariants'
  | 'itemNumber'
  | 'netWeight-kg'
  | 'tareWeight-kg'
  | 'grossWeight-kg'
  | 'grossWidth-mm'
  | 'grossHeight-mm'
  | 'grossDepth-mm'
  | 'grossVolume-l'
  | 'salesUnit'
  | 'variantDescription'
  | 'erpLegalEntity'

export enum UoM {
  Imperial = 'imperial',
  Metric = 'metric',
}

export enum ErpLegalEntity {
  AU = 12,
  US = 14,
  CA = 20,
}

export const ErpLegalEntityLocaleMap = new Map<ErpLegalEntity, string>([
  [ErpLegalEntity.AU, 'en-AU'],
  [ErpLegalEntity.US, 'en-US'],
  [ErpLegalEntity.CA, 'en-CA'],
])

export type OrderQueryParams = {
  businessUnitKey?: string
  customerId?: string | string[]
  search?: string
  from?: string
  to?: string
  shippingAddress?: string
  page: number
  limit: number
  sort?: string
}

export type OrderQueryParamsAsAssociate = OrderQueryParams & {
  associateId: string
}

export type OrderQueryParamsInStore = OrderQueryParams & {
  storeKeys: string | ReadonlyArray<string>
}

export type SearchQueryParams = {
  markMatchingVariants?: boolean
  priceChannel?: string
  priceCurrency?: string
  category?: string
  searchQuery?: string | string[]
  language?: string
  localeProjection?: string
  page?: number
  facet?: string | string[]
  expand?: string | string[]
  sort?: string
  limit?: number
  offset?: number
  where?: string
  filter?: string | string[]
  enabled?: boolean
}

export type AvailabilityStatusItemProps = {
  id: string
  key: string
  name: LocalizedString | undefined
  availableQuantity: number
  isOnStock: boolean
}

export interface BundledProduct extends ProductProjection {
  quantity: number
  totalPrice: TypedMoney
}

export interface SparePartBundle extends ProductProjection {
  bundledProducts: BundledProduct[]
  totalPrice: TypedMoney
}
