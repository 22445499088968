import getNormetLandingURL from 'utils/getNormetLandingURL'
import getSiteURL from 'utils/getSiteURL'

const NO_ACCESS_TO_PORTAL_PATHNAME = '/get-access-to-the-normet-portal/'

const PORTAL_URL_FOR_LOCALHOST = 'https://dev.portal.normet.com/'

const NORMET_PORTAL_URL = process.env.NORMET_PORTAL_URL

function getNormetPortalURL(hasAccessToPortal: boolean): string {
  // Local
  const siteURL = getSiteURL()
  if (siteURL && siteURL.includes('localhost')) {
    return PORTAL_URL_FOR_LOCALHOST
  }

  if (!NORMET_PORTAL_URL) {
    console.error('Could not find Portal URL. Please set environment variable `NORMET_PORTAL_URL`.')
    return '#'
  }

  // DEV, QA, PROD
  if (hasAccessToPortal) {
    return NORMET_PORTAL_URL
  }

  const url = new URL(getNormetLandingURL())
  url.pathname = NO_ACCESS_TO_PORTAL_PATHNAME
  return url.toString()
}

export default getNormetPortalURL
