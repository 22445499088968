import { getColor } from '@chakra-ui/theme-tools'

import { commonSizes } from '.'

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-28px) translateX(-10px)',
  backgroundColor: 'white',
  lineHeight: '4',
}
export const Form = {
  defaultProps: {
    variant: 'floating',
  },
  variants: {
    floating: (props: any) => {
      const { theme } = props
      return {
        container: {
          _focusWithin: {
            label: {
              ...activeLabelStyles,
            },
          },
          // eslint-disable-next-line max-len
          'input:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) + label, input:-webkit-autofill + label, .chakra-select__wrapper + label, .chakra-input__group + label':
            {
              ...activeLabelStyles,
            },
          input: {
            fontSize: 'md',
            borderRadius: 'sm',
            borderWidth: 1,
            borderColor: 'greyCCC',
            backgroundColor: 'white',
            _focus: {
              borderColor: 'grey999',
              boxShadow: 'none',
            },
            _autofill: {
              //Hack to override chrome autocomplete color
              boxShadow: '0 0 0px 1000px white inset',
              transition: 'background-color 5000s ease-in-out 0s',
            },

            _invalid: {
              backgroundColor: 'secondary.beige.100',
              borderColor: 'secondary.beige.600',
              boxShadow: `0 0 0 1px ${getColor(theme, 'secondary.beige.600')}`,
            },
          },
          label: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'absolute',
            backgroundColor: 'transparent',
            pointerEvents: 'none',
            mx: 3,
            px: 1,
            my: 4,
          },
        },
      }
    },
  },
  sizes: {
    ...commonSizes.input,
  },
}
