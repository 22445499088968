import { Button, ButtonProps, Flex, useDisclosure } from '@chakra-ui/react'
import { ProductVariantAvailability } from '@commercetools/platform-sdk'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'

import { useBranches } from 'commercetools/hooks/use-branches'
import { getAvailabilityStatus, AvailabilityStatus } from 'commercetools/utils/product/getAvailabilityStatus'
import { AvailabilityCircle } from 'components/modules/Availability/AvailabilityCircle'

const AvailabilityModal = dynamic(
  () => import('components/elements/AvailabilityModal').then((modal) => modal.AvailabilityModal),
  { ssr: false },
)

type Props = ButtonProps & {
  availability: ProductVariantAvailability | undefined
  partNumber: string | undefined
  setAvailabilityDialogIsOpen?: Dispatch<SetStateAction<boolean>>
}

export function Availability({ availability, partNumber, setAvailabilityDialogIsOpen, ...props }: Props): JSX.Element {
  const { t } = useTranslation('common')
  const { id: supplyChannel, all } = useBranches()

  const modal = useDisclosure()
  useEffect(() => {
    if (setAvailabilityDialogIsOpen) {
      setAvailabilityDialogIsOpen(modal.isOpen)
    }
  }, [modal.isOpen, setAvailabilityDialogIsOpen])

  const availabilityStatus = useMemo(
    () => getAvailabilityStatus(availability, supplyChannel ?? ''),
    [availability, supplyChannel],
  )

  const getAvailabilityText = (availabilityStatus: AvailabilityStatus): string => {
    switch (availabilityStatus) {
      case AvailabilityStatus.AvailableInCurrent:
        const availableQuantity =
          (availability?.channels && supplyChannel
            ? availability?.channels[supplyChannel].availableQuantity
            : undefined) || 0
        return `${availableQuantity > 50 ? '50+' : availableQuantity} ${t('in-stock').toLowerCase()}`
      case AvailabilityStatus.AvailableInOther:
        return t('in-stock-in-other-branch')
      default:
        return t('backorder')
    }
  }

  return (
    <>
      <Flex alignItems="center">
        <AvailabilityCircle availabilityStatus={availabilityStatus} isMainAvailabilityStatus={true} />
        <Button
          variant="ghost"
          size="min"
          pl={2}
          fontSize="md"
          onClick={modal.onOpen}
          color="secondary.grey.900"
          position="static"
          //Needed to let text line break on tablet
          whiteSpace={{ base: 'nowrap', md: 'normal' }}
          textAlign="start"
          {...props}
        >
          {getAvailabilityText(availabilityStatus)}
        </Button>
      </Flex>

      <AvailabilityModal
        partNumber={partNumber}
        branches={all}
        availability={availability}
        selectedChannelId={supplyChannel ?? ''}
        {...modal}
      />
    </>
  )
}
