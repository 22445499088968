import { Cart, CartDraft, CartPagedQueryResponse, UpdateAction } from '@commercetools/platform-sdk'

import { buildURLSearchParams } from 'commercetools/utils/buildURLSearchParams'

import client from '../client'

const expand = ['lineItems[*].supplyChannel']

export const fetchBuCart = (
  customerId: string | undefined,
  companyKey: string,
  storeKeys: string | ReadonlyArray<string>,
  token?: string,
): Promise<Cart> => {
  const storeKey = Array.isArray(storeKeys) ? storeKeys[0] : storeKeys

  if (!customerId || !companyKey || !storeKey) {
    throw new Error('customer id or company key or store key not set when fetching cart')
  }

  const url = `/in-store/key=${storeKey}/me/carts`
  const params = {
    where: [`cartState = "Active" and businessUnit(key="${companyKey}")`],
    sort: 'createdAt desc',
    expand,
    limit: 1,
  }

  return client
    .get<CartPagedQueryResponse>(`${url}?${buildURLSearchParams(params)}`, token)
    .then((response) => response.results[0])
}

export const fetchCart = (customerId: string | undefined, companyId: string, token?: string): Promise<Cart> => {
  if (!customerId || !companyId) {
    throw new Error('customer id or company id not set when fetching cart')
  }

  const url = `/carts`
  const params = {
    // eslint-disable-next-line max-len
    where: `cartState = "Active" and customerId = "${companyId}" and custom(fields(customerUserId(id = "${customerId}")))`,
    sort: 'createdAt desc',
    expand,
    limit: 1,
  }

  return client
    .get<CartPagedQueryResponse>(`${url}?${buildURLSearchParams(params)}`, token)
    .then((response) => response?.results[0])
}

export const createBUCart = async (draft: CartDraft, storeKey: string): Promise<Cart> => {
  return client.post<Cart, CartDraft>(`/in-store/key=${storeKey}/me/carts/`, { ...draft })
}

export type UpdateCartValue = {
  id: string
  version: number
  storeKey: string
  actions: UpdateAction | UpdateAction[]
}

export const updateBuCart = ({ id, version, storeKey, actions }: UpdateCartValue, token?: string): Promise<Cart> =>
  client.post<Cart, Omit<UpdateCartValue, 'id' | 'storeKey'>>(
    `/in-store/key=${storeKey}/me/carts/${id}`,
    {
      version,
      actions: Array.isArray(actions) ? actions : [actions],
    },
    token,
  )
